<template>
  <div>
    <b-modal
      id="modal-component-project"
      size="lg"
      no-close-on-backdrop
      header-class="d-block"
      centered
    >
      <template #modal-header>
        <h3>{{ $t('webmag.choose_project_template') }}</h3>
        <b-button
          @click="goBackInModal"
          class="position-absolute"
        >
          <b-icon icon="arrow-left-circle-fill"></b-icon>
        </b-button>
      </template>
      <template #default>
        <b-row>
          <b-col cols="12" sm="3" class="border-right-light-grey">
            <ul class="project_templates pl-2 mt-5 pb-5">
              <li class="nav-item">
                <a
                  href="#"
                  :class="{ active: (selectedSharedItem === null) }"
                  class="nav-link"
                  @click="loadEmptyContent()"
                >
                  {{ $t('modals.createItem.project.emptyProject') }}
                </a>
              </li>
              <li
                v-for="(item, key) in sharedProjects"
                class="nav-item"
                :key="`${item.type}-${key}`"
              >
                <a
                  v-if="item.type === 'page'"
                  href="#"
                  :class="{ active: (item.isActive) }"
                  class="nav-link"
                  @click="loadPreviewContent(key)">
                  {{ item.name }}
                </a>
                <div
                  v-else
                  class="d-flex flyout py-2"
                  @click="toggleFlyout(key)"
                >
                  <span class="pl-3 cursor-pointer">{{ item.name }}</span>
                  <b-icon
                    v-if="item.showChildren"
                    icon="chevron-down"
                    aria-hidden="true"
                    class="icon"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="chevron-right"
                    aria-hidden="true"
                    class="icon"
                  ></b-icon>
                </div>
                <ul
                  v-if="item.showChildren && item.children && item.children.length"
                  class="pl-3">
                  <li
                    class="pb-2"
                    v-for="(childPage, index) in item.children"
                    :key="index"
                  >
                    <a
                      v-if="childPage.type === 'page'"
                      href="#"
                      :class="{ active: (childPage.isActive) }"
                      class="py-0 nav-link"
                      @click="loadChildPreviewContent(key, index)">
                      {{ childPage.name }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" sm="9">
            <div v-if="selectedSharedItem === null" class="m-5 pb-5">
              <h4>{{ $t('modals.createItem.project.emptyProject') }}</h4>
              <p>{{ $t('modals.createItem.project.emptyProjectDesc') }}</p>
              <b-button
                class="mr-0 ml-0 green-button"
                @click="showProjectNameModal"
              >
                <b-icon
                  icon="plus-circle-fill"
                  class="mr-2"
                  aria-hidden="true"
                  scale="1"
                ></b-icon>
                {{ $t('webmag.select') }}
              </b-button>
            </div>
            <modal-content-show-item-preview
              v-else-if="groupId"
              :group-id="groupId"
              :group-slug="groupSlug"
              :group-domain="groupDomain"
              :group-path="groupPath"
              :parent-item="parentItem"
              :parent-slug-path="parentSlugPath"
              :shared-item="selectedSharedItem"
              :key="previewKey"
              @open-name-modal="showProjectNameModal"
            />
          </b-col>
        </b-row>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          @click="close()"
          block
          class="p-3"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-component-project-name"
      ref="modal-component-project-name"
      footer-class="flex-nowrap"
      centered
      no-stacking
    >
      <template #modal-header>
        <h3>{{ $t('modals.createItem.project.insertProjectName') }}</h3>
      </template>

      <template #default>
        <modal-choose-name-content
          :group-id="groupId"
          :item-name.sync="itemName"
          :item-slug.sync="itemSlug"
          :group-slug="groupSlug"
          :child-slugs="childSlugs"
          item-type="project"
          @enter-pressed="confirmOkModalByEnterKey"
        ></modal-choose-name-content>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          :disabled="!isNameSet"
          variant="primary"
          class="p-3"
          block
          @click="handleOk"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>

    <modal-acknowledge
      v-if="showAcknowledgeModal"
      :action-type="actionType"
      page-type="project"
      @close-acknowledge-modal="closeAcknowledgeModal"
    ></modal-acknowledge>
  </div>
</template>

<script>
import DuplicateSharedItem from '@/graphQlQueries/mutations/duplicateSharedItem';
import DeleteProjectTagsByProjectId from '@/graphQlQueries/mutations/deleteProjectTagsByProjectId';
import InsertPlugin from '@/graphQlQueries/mutations/insertPlugin';
import UpdateItemOrderIndex from '@/graphQlQueries/mutations/updateItemOrderIndex';
import SetIsSharedTemplateOfProjects from '@/graphQlQueries/mutations/setIsSharedTemplateOfProjects';
import SetLoadingIndicatorInCache from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import SetOrderOfProjects from '@/graphQlQueries/mutations/setOrderOfProjects';
import SetPublishStateOfPageById from '@/graphQlQueries/mutations/setPublishStateOfPageById';
import SetPublishStateOfProjectById from '@/graphQlQueries/mutations/setPublishStateOfProjectById';
import GetAllDescendantsOfItem from '@/graphQlQueries/queries/getAllDescendantsOfItem';
import GetAllPluginDataOfFolder from '@/graphQlQueries/queries/getAllPluginDataOfFolder';
import GetAllTemplateCollections from '@/graphQlQueries/queries/getAllTemplateCollections';
import GetFolderSettingsById from '@/graphQlQueries/queries/getFolderSettingsById';
import GetSharedTemplates from '@/graphQlQueries/queries/getSharedTemplates';
import GetThemeDataByThemeName from '@/graphQlQueries/queries/getThemeDataByThemeName';
import InsertNewProject from '@/graphQlQueries/mutations/insertNewProjectItem';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';
import PluginCookiesConsent from '@/defaultData/pluginCookiesConsent';
import UserDataAndAccess from '@/mixins/userDataAndAccess';
import themeSettingsDirectTop2 from '@/defaultData/themeSettingsDirectTop2';

export default {
  name: 'ModalComponentProject',
  components: {
    ModalContentShowItemPreview: () => import('@/components/modals/modalContent/ModalContentShowItemPreview.vue'),
    ModalChooseNameContent: () => import('@/components/modals/ModalChooseNameContent.vue'),
    ModalAcknowledge: () => import('@/components/modals/ModalAcknowledge.vue'),
  },
  mixins: [SendPublishedDataToServer, UserDataAndAccess],
  props: {
    itemPosition: {
      type: Number,
      default: null,
    },
    childSlugs: {
      type: Array,
      required: true,
    },
    parentId: {
      type: Number,
      default: null,
    },
    parentItem: {
      type: Object,
      required: true,
    },
    groupSlug: {
      type: String,
    },
    groupDomain: {
      type: String,
      required: true,
    },
    groupPath: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    sharedProjects: null,
    selectedSharedItem: null,
    parentSlugPath: null,
    previewKey: 1,
    itemName: null,
    itemSlug: null,
    actionType: null,
    selectedTemplateCollection: null,
    showTemplateCollectionDetails: false,
    ciOfParentFolder: null,
    pluginDataOfParentFolder: null,
    languageIdOfParent: null,
    defaultThemeName: 'direct_top2',
    showAcknowledgeModal: false,
    descendentPageIds: [],
  }),
  created() {
    this.parentSlugPath = (this.$route.params.parametersOfUrl)
      ? `/${this.$route.params.parametersOfUrl}`
      : null;
  },
  apollo: {
    languageIdOfParent: {
      query: GetFolderSettingsById,
      variables() {
        return {
          id: this.parentId,
        };
      },
      update(data) {
        if (data.folders[0]?.language_id) {
          return data.folders[0].language_id;
        }
        return null;
      },
    },
    templateCollectionList: {
      query: GetAllTemplateCollections,
      update(data) {
        return [
          { value: null, text: this.$t('webmag.choose_option') },
          ...data.template_collections.map(
            (temCol) => ({ value: temCol.template_collection_id, text: temCol.name }),
          ),
        ];
      },
    },
    ciOfParentFolder: {
      query: GetFolderSettingsById,
      variables() {
        return {
          id: this.parentId,
        };
      },
      update(data) {
        if (data.folders[0]?.corporate_identity) {
          return data.folders[0].corporate_identity;
        }
        return null;
      },
    },
    pluginDataOfParentFolder: {
      query: GetAllPluginDataOfFolder,
      variables() {
        return {
          folderId: this.parentId,
        };
      },
      update(data) {
        return data.plugins;
      },
      skip() {
        return !this.parentId;
      },
    },
    sharedProjects: {
      query: GetSharedTemplates,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        const taggedProjectTemplates = [];
        if (data.getSharedTemplates && data.getSharedTemplates.length) {
          data.getSharedTemplates.forEach((sharedProject) => {
            const parentSlugPath = (sharedProject.slug_path)
              ? sharedProject.slug_path.substring(
                0, sharedProject.slug_path.lastIndexOf('/'),
              )
              : null;

            const previewActionObject = {
              slug: sharedProject.slug,
              groupSlug: sharedProject.group_slug,
              parentSlugPath,
            };
            const iframeSourceForPreview = `${sharedProject.domain}${sharedProject.slug_path}/`;

            // eslint-disable-next-line no-param-reassign
            sharedProject = {
              iframeSourceForPreview,
              previewActionObject,
              itemName: sharedProject.name,
              ...sharedProject,
            };
            if (sharedProject.type === 'project') {
              if (sharedProject.projects_tags && sharedProject.projects_tags.length) {
                // prepare the tagged object
                sharedProject.projects_tags.forEach((tagsObject) => {
                  const tagId = tagsObject.tag.tag_id;
                  const existingItem = taggedProjectTemplates.find((item) => tagId === item.tagId);
                  if (!existingItem) {
                    taggedProjectTemplates.push({
                      name: tagsObject.tag.name,
                      tagId,
                      type: 'tag',
                      showChildren: false,
                      children: [{
                        ...sharedProject,
                        type: 'page',
                        isActive: false,
                      }],
                    });
                  } else {
                    existingItem.children.push({
                      ...sharedProject,
                      type: 'page',
                      isActive: false,
                    });
                  }
                });
              } else {
                taggedProjectTemplates.push({
                  ...sharedProject,
                  type: 'page',
                  isActive: false,
                });
              }
            }
          });
        }
        // sort after name
        taggedProjectTemplates.sort(
          // eslint-disable-next-line no-nested-ternary,max-len
          (a, b) => (a.name.toLowerCase() !== b.name.toLowerCase() ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 : 0),
        );
        // sort the children too
        taggedProjectTemplates.forEach((item) => {
          if (item.type === 'tag' && item.children.length > 1) {
            item.children.sort(
              // eslint-disable-next-line no-nested-ternary,max-len
              (a, b) => (a.name.toLowerCase() !== b.name.toLowerCase() ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 : 0),
            );
          }
        });
        return taggedProjectTemplates;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.groupId;
      },
    },
  },
  computed: {
    isNameSet() {
      return !!this.itemName;
    },
  },
  methods: {
    toggleFlyout(tagIndex) {
      this.sharedProjects[tagIndex].showChildren = !this.sharedProjects[tagIndex].showChildren;
    },
    loadEmptyContent() {
      this.removeAllActiveStateOfTabs();
      this.selectedSharedItem = null;
      this.previewKey++;
    },
    loadPreviewContent(index) {
      this.removeAllActiveStateOfTabs();
      this.sharedProjects[index].isActive = true;
      this.selectedSharedItem = this.sharedProjects[index];
      this.previewKey++;
    },
    loadChildPreviewContent(tagIndex, childIndex) {
      this.removeAllActiveStateOfTabs();
      this.sharedProjects[tagIndex].children[childIndex].isActive = true;
      this.selectedSharedItem = this.sharedProjects[tagIndex].children[childIndex];
      this.previewKey++;
    },
    removeAllActiveStateOfTabs() {
      // remove all active keys
      this.sharedProjects.forEach((sharedProject, key) => {
        if (sharedProject.isActive) {
          this.sharedProjects[key].isActive = false;
        }
        if (sharedProject.type === 'tag' && sharedProject.children.length) {
          sharedProject.children.forEach((childProject, childKey) => {
            this.sharedProjects[key].children[childKey].isActive = false;
          });
        }
      });
    },
    showProjectNameModal() {
      this.itemName = (this.selectedSharedItem) ? this.selectedSharedItem.itemName : null;
      this.$root.$emit('bv::hide::modal', 'modal-component-project');
      this.$root.$emit('bv::show::modal', 'modal-component-project-name');
    },
    goBackInModal() {
      this.$root.$emit('bv::hide::modal', 'modal-component-project');
      this.$root.$emit('bv::show::modal', 'modal-item-type');
    },
    focusOnNextButton() {
      this.$nextTick(() => {
        const button = document.getElementById('next-button');
        button.focus();
      });
    },
    confirmOkModalByEnterKey() {
      this.handleOk();
    },
    async handleOk() {
      if (this.selectedSharedItem) {
        await this.duplicateProject();
      } else {
        await this.createNewProject();
      }
    },
    async duplicateProject() {
      // run the loader
      await this.$apollo.mutate({
        mutation: SetLoadingIndicatorInCache,
        variables: {
          isIndicatorLoading: true,
        },
      });
      const dataToBeSendToTheServer = {
        slug: this.itemSlug,
        parent_slug_path: this.parentSlugPath || '/',
        group_slug: this.groupSlug,
        group_domain: this.groupDomain,
        type: 'project',
      };
      const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
        action: 'duplicateItem',
        data: dataToBeSendToTheServer,
      });
      if (publishedDataWasSuccessfulSend) {
        const duplicateObject = {
          name: this.itemName,
          slug: this.itemSlug,
          destination_folder_id: (this.parentItem.type === 'folder') ? this.parentItem.id : null,
          destination_project_id: (this.parentItem.type === 'project') ? this.parentItem.id : null,
          page_id: null,
          group_id: this.groupId,
          project_id: this.selectedSharedItem.project_id,
        };

        const { data: { duplicateSharedTemplate } } = await this.$apollo.mutate({
          mutation: DuplicateSharedItem,
          variables: {
            object: duplicateObject,
          },
        });

        if (duplicateSharedTemplate.project_id) {
          const { data } = await this.$apollo.query({
            query: GetAllDescendantsOfItem,
            variables: {
              itemPath: duplicateSharedTemplate.project.item_path,
            },
          });
          if (data.page_descendants.length) {
            this.descendentPageIds = data.page_descendants.map((page) => page.page_id);
          }
        }
        await this.unpublishDescendentProjectAndPages(duplicateSharedTemplate);
        await this.setTheOrderOfTheDuplicatedItem(duplicateSharedTemplate);
        await this.removeIsSharedTemplate(duplicateSharedTemplate);
        await this.removeTagsFromProject(duplicateSharedTemplate);
        this.$emit('insert-data');
        this.$refs['modal-component-project-name'].hide();
        await this.$apollo.mutate({
          mutation: SetLoadingIndicatorInCache,
          variables: {
            isIndicatorLoading: false,
          },
        });
        this.actionType = 'sharedCreate';
        this.showAcknowledgeModal = true;
      }
    },
    async setTheOrderOfTheDuplicatedItem(duplicateItem) {
      await this.$apollo.mutate({
        mutation: SetOrderOfProjects,
        variables: {
          projectId: duplicateItem.project_id,
          orderIndex: this.itemPosition,
        },
      });
      return 'order of duplicate item set';
    },
    async removeTagsFromProject(duplicateItem) {
      await this.$apollo.mutate({
        mutation: DeleteProjectTagsByProjectId,
        variables: {
          projectId: duplicateItem.project_id,
        },
      });
    },
    async unpublishDescendentProjectAndPages(duplicateItem) {
      // unpublish project
      await this.$apollo.mutate({
        mutation: SetPublishStateOfProjectById,
        variables: {
          projectId: duplicateItem.project_id,
          published: false,
        },
      });
      await Promise.all(this.descendentPageIds.map(async (pageId) => {
        await this.$apollo.mutate({
          mutation: SetPublishStateOfPageById,
          variables: {
            id: pageId,
            published: false,
          },
        });
      }));

      return 'updated publish status of project and page';
    },
    async removeIsSharedTemplate(duplicateItem) {
      await this.$apollo.mutate({
        mutation: SetIsSharedTemplateOfProjects,
        variables: {
          projectId: duplicateItem.project_id,
          isSharedTemplate: false,
        },
      });
      return 'set is_shared_template to false';
    },
    async createNewProject() {
      try {
        const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
          action: 'createProject',
          data: {
            slug: this.itemSlug,
            parent_slug_path: this.parentSlugPath,
            group_slug: this.groupSlug,
            group_domain: this.groupDomain,
          },
        });
        if (publishedDataWasSuccessfulSend) {
          // get the theme data
          const { data: { themes: [themeItem] } } = await this.$apollo.query({
            query: GetThemeDataByThemeName,
            variables: {
              themeName: this.defaultThemeName,
            },
            fetchPolicy: 'network-only',
          });
          // create a switch for the "direct_top2" default theme
          // we organize the theme settings in the defaultData folder not in the database
          let theme_settings = {};
          if (this.defaultThemeName === 'direct_top2') {
            theme_settings = themeSettingsDirectTop2;
          } else {
            theme_settings = themeItem.default_settings;
          }
          const {
            data: {
              insert_projects_one: {
                project_id,
              },
            },
          } = await this.$apollo.mutate({
            mutation: InsertNewProject,
            variables: {
              obj: {
                parent_folder_id: this.parentId,
                slug: this.itemSlug,
                order_index: this.itemPosition,
                template_collection_id: 1,
                name: this.itemName,
                language_id: this.languageIdOfParent,
                theme_id: themeItem.theme_id,
                theme_settings,
                corporate_identity: this.ciOfParentFolder,
                listed: false,
              },
            },
          });
          // reorder the project to the top of the list
          await this.$apollo.mutate({
            mutation: UpdateItemOrderIndex,
            variables: {
              obj: {
                project_id,
                folder_id: null,
                current_order_index: this.itemPosition,
                new_order_index: 1,
                parent_folder_id: this.parentId,
              },
            },
          });

          if (this.pluginDataOfParentFolder.length > 0) {
            // extend the object with folder and project id
            const pluginData = this.pluginDataOfParentFolder.map((pluginDataItem) => ({
              folder_id: null,
              project_id,
              settings: pluginDataItem.settings,
              handle: pluginDataItem.handle,
              enabled: pluginDataItem.enabled,
            }));
            await this.$apollo.mutate({
              mutation: InsertPlugin,
              variables: {
                object: pluginData,
              },
            });
          }
          const isWebmagAnalyticsPluginInParent = this.pluginDataOfParentFolder.some(
            (plugin) => plugin.handle === PluginCookiesConsent.handle,
          );
          if (!this.pluginDataOfParentFolder.length || !isWebmagAnalyticsPluginInParent) {
            // get the plugin data from the
            const pluginData = {
              folder_id: null,
              project_id,
              settings: PluginCookiesConsent.settings,
              handle: PluginCookiesConsent.handle,
              enabled: true,
            };
            await this.$apollo.mutate({
              mutation: InsertPlugin,
              variables: {
                object: [pluginData],
              },
            });
          }
          // create a new gist entry for project create
          if (window.gist) {
            window.gist.identify(this.email, { email: this.email, tags: 'projects_trigger' });
          }
          this.$emit('insert-data');
          this.$refs['modal-component-project-name'].hide();
          this.actionType = 'projectCreate';
          this.showAcknowledgeModal = true;
        }
      } catch (ex) {
        console.error(ex);
      }
    },
    closeAcknowledgeModal() {
      this.showAcknowledgeModal = false;
      this.$emit('insert-data');
    },
  },
};
</script>

<style scoped lang="scss">
.template-collections {
  outline: 1px solid #e0e2e4;

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .image-wrapper {
    position: relative;
    overflow: hidden;
    max-height: 250px;
  }
}
</style>

<style lang="scss">
.flyout {
  justify-content: space-between;

  &>span{
    cursor:pointer;
    color: #777C7F;
  }
}

ul {
  list-style: none;
  font-size: 15px;
}

li a {
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;

  &.active {
    color: #53dc86;
  }
}
.icon {
  position: relative;
  top: 4px;
}
</style>
